import { IDODetailsPage } from './';
// import { PoolPage } from './';


export default {
  path: 'ido',
  childRoutes: [
    // { path: 'pool', component: PoolPage },
    { path: ':idoId', component: IDODetailsPage, isIndex: true }
  ]
};
