module.exports = [
  {
    name: "Pearlim",
    description:"Pearlim is a world’s first NFT marketplace and ecosystem to offer exclusive pearl and gem based NFTs. Our aim is to offer rare and expensive pearl and gems based NFT with some NFTs being correlated with real world pearl and gems.",
    tokenAddress: "0xee7724E540DF2d70E814F991Edb82Efe1833D915",
    logo: "QmW3FgNGeD46kHEryFUw1ftEUqRw254WkKxYeKaouz7DJA",
    socialMedia: {
      telegram: "https://t.me/pearlimtoken",
      twitter: "https://www.twitter.com/pearlim_",
    },
    buyingToken: {
      name: "BUSD",
      symbol: "BUSD",
      decimal: "18",
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    idoContract: "0x980a95505366142C5EbA79DfdF45d1E145B77cd6",
    isBNB: false,



    // startDate: "",
    // endDate: "",
    // maxContribution: "",
    // target: "",
    // raisedAmount: "",
  },
];
