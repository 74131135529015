import React, { useState, useCallback, memo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { byDecimals } from 'features/helpers/bignumber';
import styles from './styles';
import { useSelector } from 'react-redux';
import SingleIDOItem from "../SingleIDOItem"

const useStyles = makeStyles(styles);

const IDO = ({
  ido,
}) => {
  const classes = useStyles();



  return (

    <Grid item xs={12} sm={12} md={10} className={classes.container} style={{ width: "100%", borderRadius: "15px", background: "#191D59" }}>
    <SingleIDOItem data={ido}/>

    </Grid>
 

   

  );
};

export default memo(IDO);
