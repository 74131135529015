export const bscPools = [

  




  {
    id: 'kite-kite',
    name: 'KITE',
    logo: 'kite/KITE.png',

    token: 'KITE',
    tokenDescription: 'KiteSync',
    tokenAddress: '0xeDe26a1ee14281b58A5238a3ff246b02358A13B6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2KITE',
    earnedTokenAddress: '0xF490A7b582faDb833E48E716bc04902721c1EE3d',
    earnContractAddress: '0xF490A7b582faDb833E48E716bc04902721c1EE3d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: 'KITE',

    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KiteSync',
    assets: ['KITE', 'KITE'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xeDe26a1ee14281b58A5238a3ff246b02358A13B6/BNB',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0xeDe26a1ee14281b58A5238a3ff246b02358A13B6',
  },




  {
    id: 'kite-kite-busd',
    name: 'KITE-BUSD LP',
    logo: 'kite/KITE-BUSD.png',

    token: 'KITE-BUSD LP',
    tokenDescription: 'KiteSync',
    tokenAddress: '0xf1a65717F86056C3bf137035AC1dE07e5a3f9a67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2KITE-BUSD',
    earnedTokenAddress: '0x16805A74f0e27869b2dA7FC43F8732BD4E4916Dd',
    earnContractAddress: '0x16805A74f0e27869b2dA7FC43F8732BD4E4916Dd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kite-kite-busd',

    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KiteSync',
    assets: ['KITE', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xeDe26a1ee14281b58A5238a3ff246b02358A13B6/BNB',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0xeDe26a1ee14281b58A5238a3ff246b02358A13B6',
  },




  {
    id: 'kite-kite-bnb',
    name: 'KITE-BNB LP',
    logo: 'kite/KITE-BNB.png',

    token: 'KITE-BNB LP',
    tokenDescription: 'KiteSync',
    tokenAddress: '0xd241cE6EB676D753be11ab01582DCE08752AaAA0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2KITE-BNB',
    earnedTokenAddress: '0x6932DAEA0dec6c758bceEc2798a70bdb2b46E9dB',
    earnContractAddress: '0x6932DAEA0dec6c758bceEc2798a70bdb2b46E9dB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kite-kite-bnb',

    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KiteSync',
    assets: ['KITE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xeDe26a1ee14281b58A5238a3ff246b02358A13B6/BNB',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0xeDe26a1ee14281b58A5238a3ff246b02358A13B6',
  },









  {
    id: 'cakev2-cake-bnb',
    name: 'CAKE-BNB LP',
    logo: 'bnb-pairs/CAKE-BNB.svg',

    token: 'CAKE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2CAKE-BNB',
    earnedTokenAddress: '0xe451BECa78c124D499c7aB3b2b0Cb9B13b9DD804',
    earnContractAddress: '0xe451BECa78c124D499c7aB3b2b0Cb9B13b9DD804',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cake-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/BNB',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
  },



  {
    id: 'cakev2-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2BNB-BUSD',
    earnedTokenAddress: '0xe0F1963244a18C9fEE2cf2713f8F792D3fc8281e',
    earnContractAddress: '0xe0F1963244a18C9fEE2cf2713f8F792D3fc8281e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BUSD', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },


  {
    id: 'cakev2-btcb-bnb',
    logo: 'bnb-pairs/BTCB-BNB.svg',
    name: 'BTCB-BNB LP',
    token: 'BTCB-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2BNB-BTCB',
    earnedTokenAddress: '0xCD358262C0e17339c1D35FdF8C0c1fa0B755AF67',
    earnContractAddress: '0xCD358262C0e17339c1D35FdF8C0c1fa0B755AF67',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTCB', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/BNB/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  },


  // {
  //   id: 'cakev2-eth-bnb',
  //   logo: 'bnb-pairs/ETH-BNB.svg',
  //   name: 'ETH-BNB LP',
  //   token: 'ETH-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2ETH-BNB',
  //   earnedTokenAddress: '0xAe6C3853810feD1dE7a0aFF119732f7d94e75178',
  //   earnContractAddress: '0xAe6C3853810feD1dE7a0aFF119732f7d94e75178',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-eth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ETH', 'BNB'],
  //   callFee: 0.5,
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  // },




{
  id: 'cakev2-usdt-busd',
  logo: 'busd-pairs/USDT-BUSD.svg',
  name: 'USDT-BUSD LP',
  token: 'USDT-BUSD LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
  tokenDecimals: 18,
  earnedToken: 'mooCakeV2USDT-BUSD',
  earnedTokenAddress: '0xA3f1AbF22e90c924AC6B535EDCd7b7FD61cF581D',
  earnContractAddress: '0xA3f1AbF22e90c924AC6B535EDCd7b7FD61cF581D',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-usdt-busd',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['USDT', 'BUSD'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059ff775485246999027b3197955',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
},


{
  id: 'cakev2-eth-bnb',
  logo: 'bnb-pairs/ETH-BNB.svg',
  name: 'ETH-BNB LP',
  token: 'ETH-BNB LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
  tokenDecimals: 18,
  earnedToken: 'mooCakeV2ETH-BNB',
  earnedTokenAddress: '0x97DEB26d8b687f7305Cc4b5B6C4D77d8B73eb9F8',
  earnContractAddress: '0x97DEB26d8b687f7305Cc4b5B6C4D77d8B73eb9F8',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-eth-bnb',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['ETH', 'BNB'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/BNB/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
},

{
  id: 'cakev2-dot-bnb',
  logo: 'bnb-pairs/DOT-BNB.svg',
  name: 'DOT-BNB LP',
  token: 'DOT-BNB LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
  tokenDecimals: 18,
  tokenDescriptionUrl: '#',
  earnedToken: 'mooCakev2DOT-BNB',
  earnedTokenAddress: '0xD36d0A161745E6444Cef019e3D2281174812Aa61',
  earnContractAddress: '0xD36d0A161745E6444Cef019e3D2281174812Aa61',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-dot-bnb',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['DOT', 'BNB'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/BNB/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
},


{
  id: 'cakev2-ada-bnb',
  logo: 'bnb-pairs/ADA-BNB.svg',
  name: 'ADA-BNB LP',
  token: 'ADA-BNB LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
  tokenDecimals: 18,
  tokenDescriptionUrl: '#',
  earnedToken: 'mooCakev2ADA-BNB',
  earnedTokenAddress: '0x01fA4B83a550C47BB24aC90a53B667b6Cf47B6a8',
  earnContractAddress: '0x01fA4B83a550C47BB24aC90a53B667b6Cf47B6a8',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-ada-bnb',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['ADA', 'BNB'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/BNB/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
},


{
  id: 'cakev2-link-bnb',
  logo: 'bnb-pairs/LINK-BNB.svg',
  name: 'LINK-BNB LP',
  token: 'LINK-BNB LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
  tokenDecimals: 18,
  earnedToken: 'mooCakeV2LINK-BNB',
  earnedTokenAddress: '0x186cf97F4150E5B25f78FB602169cfd0C7c65135',
  earnContractAddress: '0x186cf97F4150E5B25f78FB602169cfd0C7c65135',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-link-bnb',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['LINK', 'BNB'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/BNB/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
},
{
  id: 'cakev2-axs-wbnb',
  name: 'AXS-BNB LP',
  token: 'AXS-BNB LP2',
  logo: 'bnb-pairs/AXS-BNB.png',

  tokenDescription: 'PancakeSwap',
  tokenAddress: '0xC2d00De94795e60FB76Bc37d899170996cBdA436',
  tokenDecimals: 18,
  tokenDescriptionUrl: '#',
  earnedToken: 'mooCakeV2AXS-WBNB',
  earnedTokenAddress: '0x0D6FFBA5786cBC279483334b2C29b8709740A696',
  earnContractAddress: '0x0D6FFBA5786cBC279483334b2C29b8709740A696',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-axs-wbnb',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['AXS', 'BNB'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?inputCurrency=0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
},

{
  id: 'cakev2-skill-wbnb',
  name: 'SKILL-BNB LP',
  logo: 'bnb-pairs/SKILL-BNB.png',

  token: 'SKILL-BNB LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0xC19dfd34D3ba5816dF9CBDaa02D32A9F8dc6F6fC',
  tokenDecimals: 18,
  tokenDescriptionUrl: '#',
  earnedToken: 'mooCakeV2SKILL-WBNB',
  earnedTokenAddress: '0x5795eA74d9C5B7172cE78639795FA1CCBdc03b9a',
  earnContractAddress: '0x5795eA74d9C5B7172cE78639795FA1CCBdc03b9a',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-skill-wbnb',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['SKILL', 'BNB'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?inputCurrency=0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
},
{
  id: 'cakev2-usdt-usdc',
  name: 'USDT-USDC LP',
  logo: 'usdt-pairs/USDT-USDC.png',

  token: 'USDT-USDC LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
  tokenDecimals: 18,
  tokenDescriptionUrl: '#',
  earnedToken: 'mooCakeV2USDT-USDC',
  earnedTokenAddress: '0xdb78E98c067928111EfeEA0d738d9b367F7ec0cf',
  earnContractAddress: '0xdb78E98c067928111EfeEA0d738d9b367F7ec0cf',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-usdt-usdc',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['USDT', 'USDC'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/0x55d398326f99059fF775485246999027B3197955/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
},

{
  id: 'cakev2-eth-usdc',
  logo: 'eth-pairs/eth-usdc.svg',
  name: 'ETH-USDC LP',
  token: 'ETH-USDC LP2',
  tokenDescription: 'PancakeSwap',
  tokenAddress: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
  tokenDecimals: 18,
  tokenDescriptionUrl: '#',
  earnedToken: 'mooCakeV2ETH-USDC',
  earnedTokenAddress: '0x2ba4F131df9F6208553c823adf7D3564C1d24D9a',
  earnContractAddress: '0x2ba4F131df9F6208553c823adf7D3564C1d24D9a',
  pricePerFullShare: 1,
  tvl: 0,
  oracle: 'lps',
  oracleId: 'cakev2-eth-usdc',
  oraclePrice: 0,
  depositsPaused: false,
  status: 'active',
  platform: 'PancakeSwap',
  assets: ['ETH', 'USDC'],
  callFee: 0.5,
  addLiquidityUrl:
    'https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  buyTokenUrl:
    'https://pancakeswap.finance/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',

  },

  {
    id: 'cakev2-btt-busd',
    name: 'BTT-BUSD LP',
    logo: 'busd-pairs/BTT-BUSD.png',

    token: 'BTT-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xdcfbB12DED3FEa12D2A078Bc6324131cD14bF835',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BTT-BUSD',
    earnedTokenAddress: '0x7f3301b7530346Ea0b6AF0B64447214DE6284D59',
    earnContractAddress: '0x7f3301b7530346Ea0b6AF0B64447214DE6284D59',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btt-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTT', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8595f9da7b868b1822194faed312235e43007b49',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x8595f9da7b868b1822194faed312235e43007b49',
  },

  {
    id: 'cakev2-trx-busd',
      logo: 'busd-pairs/TRX-BUSD.svg',

    name: 'TRX-BUSD LP',
    token: 'TRX-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xb5D108578Be3750209d1b3A8f45FFee8C5a75146',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TRX-BUSD',
    earnedTokenAddress: '0x756552726DfD0f4FA0Ec119C02695c066974eb5C',
    earnContractAddress: '0x756552726DfD0f4FA0Ec119C02695c066974eb5C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-trx-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TRX', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
  },
]
