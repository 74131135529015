import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import Grid from "@material-ui/core/Grid";

import { useConnectWallet } from "features/home/redux/hooks";
import {
  useFetchBalances,
  useFetchIDOData,
  useFetchApys,
} from "../../redux/hooks";
import VisibleIDOs from "../VisibleIDOs/VisibleIDOs";
import styles from "./styles";
import LoadingLottie from "components/LoadingLottie";

const FETCH_INTERVAL_MS = 15 * 1000;

const useStyles = makeStyles(styles);

export default function AllIdoPage() {
  const { web3, address } = useConnectWallet();
  const { idos, fetchIDOData, fetchIDODataPending, fetchIDODataDone } =
    useFetchIDOData();
  const { tokens, fetchBalances, fetchBalancesPending, fetchBalancesDone } =
    useFetchBalances();

  const [isLoading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && !fetchBalancesPending) {
        fetchBalances({ address, web3, tokens });
      }
      if (!fetchIDODataPending) {
        fetchIDOData({ web3 });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchIDOData]);




  return (
    <Grid container className={classes.container}>
      <Grid item xs={6}></Grid>
      <Grid container xs={12}>
        <Grid md={6} xs={12}>
          <h3 className={classes.text}>Kite Sync IDO</h3>
        </Grid>
      </Grid>

      {fetchIDODataDone ?  <VisibleIDOs idos={idos}/>:<LoadingLottie /> }
    </Grid>
  );
}
