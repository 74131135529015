const styles = theme => ({
  container: {
    marginBottom: '24px',
    // border: '1px solid ' + theme.palette.background.border,
  },
  accordion: {
    width: '100%',
    // maxWidth:"440px",
    margin: "10px",
    // display:"inline-flex",
    borderRadius: "20px",
    backgroundColor: theme.palette.background.primary,
  },
  divider: {
    margin: '0 30px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:"100%"
  },
  modalBody:{

    padding:"20px",
    background:"#8538bd",
    borderRadius:"20px",
    alignItems:"center",
    // width:"65%"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

export default styles;
