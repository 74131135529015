import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = isNightMode =>
  createMuiTheme({
    palette: {
      type: isNightMode ? 'dark' : 'light',
      background: {
        default: isNightMode ? '#101241' : '#101241',
        paper: isNightMode ? '#606077' : '#606077',
        primary: isNightMode ? '#191d59' : '#191d59',
        secondary: isNightMode ? '#F8F2EC' : '#F8F2EC',
        extra: isNightMode ? '#101241' : '#101241 ',
        dark: isNightMode ? '#F8F2EC' : '#F8F2EC',
        paused: isNightMode ? '#F8F2EC' : '#F8F2EC',
        retired: isNightMode ? '#F8F2EC' : '#F8F2EC',
        hover: isNightMode ? '#F8F2EC' : '#F8F2EC',
        border: isNightMode ? '' : '',
      },
      primary: {
        main: isNightMode ? '#fff' : '#fff',
      },
      secondary: {
        main: isNightMode ? '#fff' : '#F8F2EC',
      },
      text: {
        primary: isNightMode ? '#fff' : '#fff',
        secondary: isNightMode ? '#fff' : '#fff',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: isNightMode ? '#fff' : '#fff',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color: isNightMode ? '#fff' : '#fff',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: isNightMode ? '#fff' : '#fff',
        },
        colorSecondary: {
          color: isNightMode ? '#fff' : '#fff',
        },
      },
    },
  });

export default createTheme;
