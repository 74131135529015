export const polygonPools = [
  {
    id: 'polygon-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolygonBIFI',
    earnedTokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    earnContractAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    callFee: 0.05,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'aave-wbtc',
    logo: 'single-assets/BTCB.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: 'Aave',
    tokenAddress: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveWBTC',
    earnedTokenAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    earnContractAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['WBTC'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  },
  {
    id: 'aave-eth',
    logo: 'single-assets/ETH.png',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Aave',
    tokenAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveETH',
    earnedTokenAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    earnContractAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['ETH'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-titan-eth',
    logo: 'polygon/TITAN-ETH.png',
    name: 'TITAN-ETH LP',
    token: 'TITAN-ETH LP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xA28Ade2f27b9554b01964FDCe97eD643301411d9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickTITAN-ETH',
    earnedTokenAddress: '0x1b27b7083a9BdA1aCD04266F61DF646f8296a872',
    earnContractAddress: '0x1b27b7083a9BdA1aCD04266F61DF646f8296a872',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-titan-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['TITAN', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
  },
  {
    id: 'iron-iron-usdc-quick',
    logo: 'polygon/IRON-USDC.png',
    name: 'IRON-USDC LP',
    token: 'IRON-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Bbe0F728f4d5821F84eeE0432D2A4be7C0cB7Fc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronQuickIRON-USDC',
    earnedTokenAddress: '0xe5D9d4F4C6369E24c8dA4cB07cCb2901845f6021',
    earnContractAddress: '0xe5D9d4F4C6369E24c8dA4cB07cCb2901845f6021',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-usdc-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['IRON', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
  },
  {
    id: 'polycat-sushi-fish-matic',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xcBF6f78981e63Ef813cb71852d72A060b583EECF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatSushiFISH-MATIC',
    earnedTokenAddress: '0xefA8E30cE4cc433cEA1B3b6006D69731A4FBd485',
    earnContractAddress: '0xefA8E30cE4cc433cEA1B3b6006D69731A4FBd485',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-sushi-fish-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polycat',
    assets: ['FISH', 'MATIC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x3a3df212b7aa91aa0402b9035b098891d276572b',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x3a3df212b7aa91aa0402b9035b098891d276572b',
  },
  {
    id: 'polycat-quick-fish-matic',
    logo: 'polygon/FISH-MATIC.svg',
    name: 'FISH-MATIC LP',
    token: 'FISH-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x289cf2B63c5Edeeeab89663639674d9233E8668E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolycatQuickFISH-MATIC',
    earnedTokenAddress: '0x7eE71053102d54Fc843BaEBaf07277C2b6dB64f1',
    earnContractAddress: '0x7eE71053102d54Fc843BaEBaf07277C2b6dB64f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polycat-quick-fish-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polycat',
    assets: ['FISH', 'MATIC'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
  },
  {
    id: 'sushi-grt-eth',
    logo: 'polygon/GRT-ETH.png',
    name: 'GRT-ETH SLP',
    token: 'GRT-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x1cedA73C034218255F50eF8a2c282E6B4c301d60',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiGRT-ETH',
    earnedTokenAddress: '0x8efCf419813F9E018cCaCda36151e5079c274fa4',
    earnContractAddress: '0x8efCf419813F9E018cCaCda36151e5079c274fa4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-grt-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['GRT', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x5fe2B58c013d7601147DcdD68C143A77499f5531/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x5fe2B58c013d7601147DcdD68C143A77499f5531&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-frax-fxs',
    logo: 'polygon/FRAX-FXS.png',
    name: 'FRAX-FXS SLP',
    token: 'FRAX-FXS SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xd53a56AE0f48c9a03660cd36c2E4ae20493a1Eca',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-FXS',
    earnedTokenAddress: '0xddD5F39d044Dbaeef7b348cf04C3628acd3F1D8f',
    earnContractAddress: '0xddD5F39d044Dbaeef7b348cf04C3628acd3F1D8f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-frax-fxs',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['FRAX', 'FXS'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x104592a158490a9228070E0A8e5343B499e125D0/0x3e121107F6F22DA4911079845a470757aF4e1A1b',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x104592a158490a9228070E0A8e5343B499e125D0&outputCurrency=0x3e121107F6F22DA4911079845a470757aF4e1A1b',
  },
  {
    id: 'sushi-frax-usdc',
    logo: 'polygon/FRAX-USDC.png',
    name: 'FRAX-USDC SLP',
    token: 'FRAX-USDC SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x9e20a8d3501BF96EDA8e69b96DD84840058a1cB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-USDC',
    earnedTokenAddress: '0x5B19A2e8E5878cF2b1E9b1AC7CEA50346671B2Fc',
    earnContractAddress: '0x5B19A2e8E5878cF2b1E9b1AC7CEA50346671B2Fc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-frax-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['FRAX', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x104592a158490a9228070E0A8e5343B499e125D0/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x104592a158490a9228070E0A8e5343B499e125D0&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'sushi-wmatic-woofy',
    logo: 'polygon/MATIC-WOOFY.png',
    name: 'MATIC-WOOFY SLP',
    token: 'MATIC-WOOFY SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x211F8e61113eDAf00cf37A804B0bA721875Ef560',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMATIC-WOOFY',
    earnedTokenAddress: '0x544551E4E7D7bDd1CfbD55F07e304F5C9fD514Dd',
    earnContractAddress: '0x544551E4E7D7bDd1CfbD55F07e304F5C9fD514Dd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wmatic-woofy',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['WMATIC', 'WOOFY'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270&outputCurrency=0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
  },
  {
    id: 'sushi-renDOGE-eth',
    logo: 'polygon/renDOGE-ETH.png',
    name: 'renDOGE-ETH SLP',
    token: 'renDOGE-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x3398787dCa0F1bD830713eC5294aDE196a198984',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushirenDOGE-ETH',
    earnedTokenAddress: '0x22dFf003e7A48d00fA7b671a693A5D94a0893853',
    earnContractAddress: '0x22dFf003e7A48d00fA7b671a693A5D94a0893853',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-renDOGE-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['renDOGE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x7C4A54f5d20b4023D6746F1f765f4DFe7C39a7e6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7C4A54f5d20b4023D6746F1f765f4DFe7C39a7e6&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-usdc-dai',
    logo: 'polygon/USDC-DAI.png',
    name: 'USDC-DAI SLP',
    token: 'USDC-DAI SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xCD578F016888B57F1b1e3f887f392F0159E26747',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-DAI',
    earnedTokenAddress: '0x75424BE5378621AeC2eEF25965f40FeB59039B52',
    earnContractAddress: '0x75424BE5378621AeC2eEF25965f40FeB59039B52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'DAI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  },
  {
    id: 'sushi-snx-eth',
    logo: 'polygon/SNX-ETH.png',
    name: 'SNX-ETH SLP',
    token: 'SNX-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x116Ff0d1Caa91a6b94276b3471f33dbeB52073E7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiSNX-ETH',
    earnedTokenAddress: '0x3AD9cd8d75f7a711Caea58e725425A9dC0D249c4',
    earnContractAddress: '0x3AD9cd8d75f7a711Caea58e725425A9dC0D249c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-snx-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['SNX', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x50B728D8D964fd00C2d0AAD81718b71311feF68a/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x50B728D8D964fd00C2d0AAD81718b71311feF68a&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'iron-titan',
    logo: 'polygon/TITAN.png',
    name: 'TITAN',
    token: 'TITAN',
    tokenDescription: 'IronFinance',
    tokenAddress: '0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN',
    earnedTokenAddress: '0x8e3e85c7d4A616683f3F9468bC17169774cD1aFC',
    earnContractAddress: '0x8e3e85c7d4A616683f3F9468bC17169774cD1aFC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TITAN',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['TITAN'],
    callFee: 0.05,
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
  },
  {
    id: 'iron-titan-iron',
    logo: 'polygon/TITAN-IRON.png',
    name: 'TITAN-IRON LP',
    token: 'TITAN-IRON LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x35c1895DAC1e2432b320e2927b4F71a0D995602F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN-IRON',
    earnedTokenAddress: '0x9525A39b7E642785c10F02287b6A181CE9552f44',
    earnContractAddress: '0x9525A39b7E642785c10F02287b6A181CE9552f44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-titan-iron',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['TITAN', 'IRON'],
    callFee: 0.05,
    addLiquidityUrl:
      'https://app.sushi.com/add/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A&outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
  },
  {
    id: 'quick-bifi-eth',
    logo: 'polygon/BIFI-ETH.png',
    name: 'BIFI-ETH LP',
    token: 'BIFI-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x8b80417D92571720949fC22404200AB8FAf7775f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickBIFI-ETH',
    earnedTokenAddress: '0x21bA98fCb000dFeD8eC3B94cB41BeA51A601A94c',
    earnContractAddress: '0x21bA98fCb000dFeD8eC3B94cB41BeA51A601A94c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bifi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BIFI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'quick-bifi-quick',
    logo: 'polygon/BIFI-QUICK.png',
    name: 'BIFI-QUICK LP',
    token: 'BIFI-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xC6a23BDd628c3B17fc0dF270e6BF8E48511950e2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickBIFI-QUICK',
    earnedTokenAddress: '0xCC2755476B0573F0ee0D5a754Bb6fE720c3641Bb',
    earnContractAddress: '0xCC2755476B0573F0ee0D5a754Bb6fE720c3641Bb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-bifi-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['BIFI', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'quick-vision-quick',
    logo: 'polygon/VISION-QUICK.png',
    name: 'VISION-QUICK LP',
    token: 'VISION-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x52F31162e07C0158C5dDa8c922cA09B52881e471',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickVISION-QUICK',
    earnedTokenAddress: '0x9a3892d43363083fa8b557C3db8B8652E7061612',
    earnContractAddress: '0x9a3892d43363083fa8b557C3db8B8652E7061612',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-vision-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['VISION', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x034b2090b579228482520c589dbd397c53fc51cc',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x034b2090b579228482520c589dbd397c53fc51cc',
  },
  {
    id: 'quick-degen-quick',
    logo: 'polygon/DEGEN-QUICK.png',
    name: 'DEGEN-QUICK LP',
    token: 'DEGEN-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x75a4917aeE97BDA48D3572f431e04003743da85e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEGEN-QUICK',
    earnedTokenAddress: '0xe942A8Ef245EAC3CEa951486e3Df5764C79b9621',
    earnContractAddress: '0xe942A8Ef245EAC3CEa951486e3Df5764C79b9621',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-degen-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEGEN', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
  },
  {
    id: 'quick-cc10-quick',
    logo: 'polygon/CC10-QUICK.png',
    name: 'CC10-QUICK LP',
    token: 'CC10-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x42fD10ddc7628b82d80c3b2C286F3e79555Fd7A1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCC10-QUICK',
    earnedTokenAddress: '0x226a18Fb5eb7d9d1c4Eb1b5Cff957E0F1e3fd9Ed',
    earnContractAddress: '0x226a18Fb5eb7d9d1c4Eb1b5Cff957E0F1e3fd9Ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cc10-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CC10', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
  },
  {
    id: 'quick-defi5-quick',
    logo: 'polygon/DEFI5-QUICK.png',
    name: 'DEFI5-QUICK LP',
    token: 'DEFI5-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xa975CEEb05B379Cf67FdfA63e7b2770f9F1D72c6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEFI5-QUICK',
    earnedTokenAddress: '0x191E0b3B023fd8911c1D7632086A46C0D2dB39ed',
    earnContractAddress: '0x191E0b3B023fd8911c1D7632086A46C0D2dB39ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-defi5-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEFI5', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x42435F467D33e5C4146a4E8893976ef12BBCE762',
  },
  {
    id: 'quick-btc-usdc',
    logo: 'polygon/BTC-USDC.png',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF6a637525402643B0654a54bEAd2Cb9A83C8B498',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickwBTC-USDC',
    earnedTokenAddress: '0x91a55E4b057119e20334258f7C5EAB8822491CEb',
    earnContractAddress: '0x91a55E4b057119e20334258f7C5EAB8822491CEb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-btc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WBTC', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'iron-titan-matic',
    logo: 'polygon/TITAN.png',
    name: 'TITAN-MATIC LP',
    token: 'TITAN-MATIC LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xA79983Daf2A92c2C902cD74217Efe3D8AF9Fba2a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronTITAN-MATIC',
    earnedTokenAddress: '0xE63aCEbE35265896cC6E8BdB8eCC0640a1807141',
    earnContractAddress: '0xE63aCEbE35265896cC6E8BdB8eCC0640a1807141',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-titan-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['TITAN', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
  },
  {
    id: 'iron-iron-usdc',
    logo: 'polygon/IRON-USDC.png',
    name: 'IRON-USDC LP',
    token: 'IRON-USDC LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x85dE135fF062Df790A5f20B79120f17D3da63b2d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronIRON-USDC',
    earnedTokenAddress: '0x3e349b83A3E68bdD5BB71fAB63dDE123c478FEA4',
    earnContractAddress: '0x3e349b83A3E68bdD5BB71fAB63dDE123c478FEA4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'IronFinance',
    assets: ['IRON', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?outputCurrency=0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
  },
  {
    id: 'quick-matic-usdc',
    logo: 'polygon/MATIC-USDC.png',
    name: 'MATIC-USDC LP',
    token: 'MATIC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickMATIC-USDC',
    earnedTokenAddress: '0xC1A2e8274D390b67A7136708203D71BF3877f158',
    earnContractAddress: '0xC1A2e8274D390b67A7136708203D71BF3877f158',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-matic-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MATIC', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'quick-vision-eth',
    logo: 'polygon/VISION-ETH.png',
    name: 'VISION-ETH LP',
    token: 'VISION-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x47Be4b1b6921a36591142E108B8C9E04BB55E015',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickVISION-ETH',
    earnedTokenAddress: '0xAbA81D550C326DFf2cE0D31bC7Aa6289d576591E',
    earnContractAddress: '0xAbA81D550C326DFf2cE0D31bC7Aa6289d576591E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-vision-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['VISION', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x034b2090b579228482520c589dbd397c53fc51cc',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x034b2090b579228482520c589dbd397c53fc51cc',
  },
  {
    id: 'curve-am3crv',
    logo: 'polygon/CURVE-3Pool.png',
    name: 'DAI/USDC/USDT',
    token: 'am3CRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveAm3CRV',
    earnedTokenAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    earnContractAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-am3crv',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAI', 'USDC', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl: 'https://polygon.curve.fi/aave/deposit',
  },
  {
    id: 'quick-degen-eth',
    logo: 'polygon/DEGEN-ETH.png',
    name: 'DEGEN-ETH LP',
    token: 'DEGEN-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xFdBDb3A2bbdC7d9dC6203DcEf9D53f1735135951',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEGEN-ETH',
    earnedTokenAddress: '0xF7A5eC9168B4C5688b3ad599Aa5c8E1922fEacAE',
    earnContractAddress: '0xF7A5eC9168B4C5688b3ad599Aa5c8E1922fEacAE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-degen-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEGEN', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
  },
  {
    id: 'quick-cc10-eth',
    logo: 'polygon/CC10-ETH.png',
    name: 'CC10-ETH LP',
    token: 'CC10-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xBF646010B1ae86aD7fBb4cFf5fd93C7019331Cc9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCC10-ETH',
    earnedTokenAddress: '0x6F6bbbCA49b4b2cE0E27eb0156977048AC3434B9',
    earnContractAddress: '0x6F6bbbCA49b4b2cE0E27eb0156977048AC3434B9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cc10-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CC10', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
  },
  {
    id: 'adamant-addy',
    logo: 'polygon/ADDY.png',
    name: 'ADDY',
    token: 'ADDY',
    tokenDescription: 'Adamant',
    tokenAddress: '0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAdamantADDY',
    earnedTokenAddress: '0x6b7c061949BE60d59F857fcc08b76c278c3B73C0',
    earnContractAddress: '0x6b7c061949BE60d59F857fcc08b76c278c3B73C0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ADDY',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Adamant',
    assets: ['ADDY'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539',
  },
  {
    id: 'pzap-pzap-usdc',
    logo: 'polygon/PZAP-USDC.png',
    name: 'PZAP-USDC LP',
    token: 'PZAP-USDC LP',
    tokenDescription: 'Polyzap',
    tokenAddress: '0x1df7ADAeD1F7f36C822A3079198815b284aD8475',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPzapPZAP-USDC',
    earnedTokenAddress: '0xBA4FA9A5e1e573fA5267970238af5Edf40727315',
    earnContractAddress: '0xBA4FA9A5e1e573fA5267970238af5Edf40727315',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pzap-pzap-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyzap',
    assets: ['PZAP', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://polyzap.app/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    buyTokenUrl:
      'https://polyzap.app/#/swap?outputCurrency=0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
  },
  {
    id: 'pzap-pzap-matic',
    logo: 'polygon/PZAP-MATIC.png',
    name: 'PZAP-MATIC LP',
    token: 'PZAP-MATIC LP',
    tokenDescription: 'Polyzap',
    tokenAddress: '0xf627E14c318045066dAe0A36997c8C1F34fC0D4c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPzapPZAP-MATIC',
    earnedTokenAddress: '0xf2984c27B963A14F9f3B7326096c54fb05d5b9AF',
    earnContractAddress: '0xf2984c27B963A14F9f3B7326096c54fb05d5b9AF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pzap-pzap-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Polyzap',
    assets: ['PZAP', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl: 'https://polyzap.app/#/add/ETH/0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
    buyTokenUrl:
      'https://polyzap.app/#/swap?outputCurrency=0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
  },
  {
    id: 'cometh-bifi-eth',
    logo: 'polygon/BIFI-ETH.png',
    name: 'BIFI-ETH LP',
    token: 'BIFI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0xee3987aa1B20944EF4f8f049Cf1763007C06cFBC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethBIFI-ETH',
    earnedTokenAddress: '0x9649e6E5c689f21BC27B47CE4177f7c5f7281E20',
    earnContractAddress: '0x9649e6E5c689f21BC27B47CE4177f7c5f7281E20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-bifi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['BIFI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'cometh-bifi-must',
    logo: 'polygon/BIFI-MUST.png',
    name: 'BIFI-MUST LP',
    token: 'BIFI-MUST LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x9292aa90F81dc77fE6e6BB34b276502b6a047F7d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethBIFI-MUST',
    earnedTokenAddress: '0x66b3d910c30f2305EA0078E06DF65e0c1745ceF0',
    earnContractAddress: '0x66b3d910c30f2305EA0078E06DF65e0c1745ceF0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-bifi-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['BIFI', 'MUST'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f/0xfbdd194376de19a88118e84e279b977f165d01b8',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f&outputCurrency=0xfbdd194376de19a88118e84e279b977f165d01b8',
  },
  {
    id: 'aave-matic',
    logo: 'single-assets/MATIC.svg',
    name: 'MATIC',
    token: 'MATIC',
    tokenDescription: 'Aave',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveMATIC',
    earnedTokenAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    earnContractAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WMATIC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['MATIC'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  {
    id: 'aave-aave',
    logo: 'single-assets/AAVE.svg',
    name: 'AAVE',
    token: 'AAVE',
    tokenDescription: 'Aave',
    tokenAddress: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveAAVE',
    earnedTokenAddress: '0xe4b3CCbB1E48c579Ea3D764Fb258Bc908e46487E',
    earnContractAddress: '0xe4b3CCbB1E48c579Ea3D764Fb258Bc908e46487E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AAVE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['AAVE'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
  },
  {
    id: 'aave-dai',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Aave',
    tokenAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveDAI',
    earnedTokenAddress: '0x9B36ECeaC46B70ACfB7C2D6F3FD51aEa87C31018',
    earnContractAddress: '0x9B36ECeaC46B70ACfB7C2D6F3FD51aEa87C31018',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['DAI'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  },
  {
    id: 'aave-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Aave',
    tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDC',
    earnedTokenAddress: '0xE71f3C11D4535a7F8c5FB03FDA57899B2C9c721F',
    earnContractAddress: '0xE71f3C11D4535a7F8c5FB03FDA57899B2C9c721F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['USDC'],
    callFee: 0.5,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  },
  {
    id: 'sushi-crv-eth',
    logo: 'polygon/CRV-ETH.png',
    name: 'CRV-ETH LP',
    token: 'CRV-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x396E655C309676cAF0acf4607a868e0CDed876dB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiCRV-ETH',
    earnedTokenAddress: '0xE695fCeD8fD93eeE54204a7fC33323a60d41865A',
    earnContractAddress: '0xE695fCeD8fD93eeE54204a7fC33323a60d41865A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-crv-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['CRV', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x172370d5cd63279efa6d502dab29171933a610af/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x172370d5cd63279efa6d502dab29171933a610af&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x4B1F1e2435A9C96f7330FAea190Ef6A7C8D70001',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-USDT',
    earnedTokenAddress: '0xB6B89a05ad8228b98d0D8a77e1a695c54500db3b',
    earnContractAddress: '0xB6B89a05ad8228b98d0D8a77e1a695c54500db3b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'sushi-link-eth',
    logo: 'polygon/LINK-ETH.png',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x74D23F21F780CA26B47Db16B0504F2e3832b9321',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiLINK-ETH',
    earnedTokenAddress: '0xaE65a66B3c7f8cc1ba71cEA740C953aBa0F3Ce8b',
    earnContractAddress: '0xaE65a66B3c7f8cc1ba71cEA740C953aBa0F3Ce8b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['LINK', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-mocean-matic',
    logo: 'polygon/mOCEAN-MATIC.png',
    name: 'mOCEAN-MATIC LP',
    token: 'mOCEAN-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5a94F81D25c73eDdBDD84b84E8F6D36C58270510',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickmOCEAN-MATIC',
    earnedTokenAddress: '0x82303a4b2c821204A84AB2a068eC8EDf3Bc23061',
    earnContractAddress: '0x82303a4b2c821204A84AB2a068eC8EDf3Bc23061',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mocean-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['mOCEAN', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x282d8efce846a88b159800bd4130ad77443fa1a1',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  },
  {
    id: 'quick-any-quick',
    logo: 'polygon/ANY-QUICK.png',
    name: 'ANY-QUICK LP',
    token: 'ANY-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xeb275d1d930F157504cca7D7AFCe38360C7302b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickANY-QUICK',
    earnedTokenAddress: '0x2849095eE44eCd5f60Ed04f94e5BB45623A8e75a',
    earnContractAddress: '0x2849095eE44eCd5f60Ed04f94e5BB45623A8e75a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-any-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ANY', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'quick-frax-quick',
    logo: 'polygon/FRAX-QUICK.png',
    name: 'FRAX-QUICK LP',
    token: 'FRAX-QUICK QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2Aa7a18cEAbf2eF893D2f7c0145cC45e6f10b223',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickFRAX-QUICK',
    earnedTokenAddress: '0x442ca31De7E6732455e60e3641Ac076fa7a0905f',
    earnContractAddress: '0x442ca31De7E6732455e60e3641Ac076fa7a0905f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-frax-quick',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['FRAX', 'QUICK'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x104592a158490a9228070e0a8e5343b499e125d0/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x104592a158490a9228070e0a8e5343b499e125d0&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'sushi-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x2813D43463C374a680f235c428FB1D7f08dE0B69',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiAAVE-ETH',
    earnedTokenAddress: '0x866a910F3375d0dEBDdD904A36B3940aFcD29900',
    earnContractAddress: '0x866a910F3375d0dEBDdD904A36B3940aFcD29900',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['AAVE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-eth-dai',
    logo: 'polygon/ETH-DAI.png',
    name: 'ETH-DAI LP',
    token: 'ETH-DAI SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x6FF62bfb8c12109E8000935A6De54daD83a4f39f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-DAI',
    earnedTokenAddress: '0x6b6ca47520dDC9333B8bD09a1e64204648B63274',
    earnContractAddress: '0x6b6ca47520dDC9333B8bD09a1e64204648B63274',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'DAI'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  },
  {
    id: 'sushi-btc-eth',
    logo: 'polygon/ETH-BTC.png',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xE62Ec2e799305E0D367b0Cc3ee2CdA135bF89816',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiBTC-ETH',
    earnedTokenAddress: '0x6530E351074f1f9fdf254dC7d7d8A44324E158a4',
    earnContractAddress: '0x6530E351074f1f9fdf254dC7d7d8A44324E158a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['WBTC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-eth-usdt',
    logo: 'polygon/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc2755915a85C6f6c1C0F3a86ac8C058F11Caa9C9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-USDT',
    earnedTokenAddress: '0xE8417099971151CD5CfAE264e25634Fac05cA2b3',
    earnContractAddress: '0xE8417099971151CD5CfAE264e25634Fac05cA2b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  {
    id: 'sushi-usdc-eth',
    logo: 'polygon/USDC-ETH.png',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-ETH',
    earnedTokenAddress: '0xE4DB97A2AAFbfef40D1a4AE8B709f61d6756F8e1',
    earnContractAddress: '0xE4DB97A2AAFbfef40D1a4AE8B709f61d6756F8e1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'sushi-matic-eth',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'MATIC-ETH SLP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xc4e595acDD7d12feC385E5dA5D43160e8A0bAC0E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMATIC-ETH',
    earnedTokenAddress: '0xC8e809a9180d637Cc23dAf60b41B70CA1ad5Fc08',
    earnContractAddress: '0xC8e809a9180d637Cc23dAf60b41B70CA1ad5Fc08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-matic-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['MATIC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://app.sushi.com/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.sushi.com/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'cometh-azuki-eth',
    logo: 'polygon/AZUKI-ETH.png',
    name: 'AZUKI-ETH LP',
    token: 'AZUKI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x92Bb3233F59561FC1fEC53EfC3339E4Af8E917F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethAZUKI-ETH',
    earnedTokenAddress: '0xe95d14D09a8F6034C582bd993A2F2aA8ecEC72f0',
    earnContractAddress: '0xe95d14D09a8F6034C582bd993A2F2aA8ecEC72f0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-azuki-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['AZUKI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x7CdC0421469398e0F3aA8890693d86c840Ac8931/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x7CdC0421469398e0F3aA8890693d86c840Ac8931',
  },
  {
    id: 'cometh-doki-eth',
    logo: 'polygon/DOKI-ETH.png',
    name: 'DOKI-ETH LP',
    token: 'DOKI-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0xcCeD5cB001D6081c4561bf7911F11Ccd9aAA1474',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethDOKI-ETH',
    earnedTokenAddress: '0x383489a0A5deA3f1499c638e0258F7e6a68a253f',
    earnContractAddress: '0x383489a0A5deA3f1499c638e0258F7e6a68a253f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-doki-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['DOKI', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C',
  },
  {
    id: 'quick-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2cF7252e74036d1Da831d11089D326296e64a728',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUSDC-USDT',
    earnedTokenAddress: '0x4462817b53E76b722c2D174D0148ddb81452f1dE',
    earnContractAddress: '0x4462817b53E76b722c2D174D0148ddb81452f1dE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  {
    id: 'cometh-must-eth',
    logo: 'polygon/MUST-ETH.png',
    name: 'MUST-ETH LP',
    token: 'MUST-ETH LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x8826C072657983939c26E684edcfb0e4133f0B3d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethMUST-ETH',
    earnedTokenAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    earnContractAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-must-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['MUST', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
  },
  {
    id: 'quick-eth-btc',
    logo: 'polygon/ETH-BTC.png',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xdC9232E2Df177d7a12FdFf6EcBAb114E2231198D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickwBTC-ETH',
    earnedTokenAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    earnContractAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WBTC', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  },
  {
    id: 'quick-ubt-eth',
    logo: 'polygon/UBT-ETH.png',
    name: 'UBT-ETH LP',
    token: 'UBT-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xcc203f45A31aE086218170F6A9e9623fA1655486',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickUBT-ETH',
    earnedTokenAddress: '0x942aa6324E5D0C102d3Ad6607495ac5e798a991a',
    earnContractAddress: '0x942aa6324E5D0C102d3Ad6607495ac5e798a991a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-ubt-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['UBT', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x7FBc10850caE055B27039aF31bD258430e714c62',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x7FBc10850caE055B27039aF31bD258430e714c62',
  },
  {
    id: 'quick-defi5-eth',
    logo: 'polygon/DEFI5-ETH.png',
    name: 'DEFI5-ETH LP',
    token: 'DEFI5-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x654E651b658f784406125400cf648588CB9773e8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickDEFI5-ETH',
    earnedTokenAddress: '0x53e674D5cC969930420d73E4b79Ee0D46cCdf6c4',
    earnContractAddress: '0x53e674D5cC969930420d73E4b79Ee0D46cCdf6c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-defi5-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DEFI5', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x42435F467D33e5C4146a4E8893976ef12BBCE762',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x42435F467D33e5C4146a4E8893976ef12BBCE762',
  },
  {
    id: 'quick-wise-eth',
    logo: 'polygon/WISE-ETH.png',
    name: 'WISE-ETH LP',
    token: 'WISE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xDF8139e9BebecAdEcF48BeC8c8064CcEfB618e2b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickWISE-ETH',
    earnedTokenAddress: '0x76cE86B1e1b7a3983B26F7E57B2A0C8896f7eB0D',
    earnContractAddress: '0x76cE86B1e1b7a3983B26F7E57B2A0C8896f7eB0D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-wise-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['WISE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
  },
  {
    id: 'quick-cel-eth',
    logo: 'polygon/CEL-ETH.png',
    name: 'CEL-ETH LP',
    token: 'CEL-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xEAA5E4620373d9Ded4DCB9267F46fcfc6698c867',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickCEL-ETH',
    earnedTokenAddress: '0xeF1870FddC25586636bf8a3d7cCf4d298f6E072e',
    earnContractAddress: '0xeF1870FddC25586636bf8a3d7cCf4d298f6E072e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-cel-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['CEL', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
  },
  {
    id: 'quick-quick-eth',
    logo: 'polygon/QUICK-ETH.png',
    name: 'QUICK-ETH LP',
    token: 'QUICK-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x1Bd06B96dd42AdA85fDd0795f3B4A79DB914ADD5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-ETH',
    earnedTokenAddress: '0x66df1B2d22759D03A9f37BAaAc826089e56a5936',
    earnContractAddress: '0x66df1B2d22759D03A9f37BAaAc826089e56a5936',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'quick-mausdc-usdc-eol',
    logo: 'polygon/maUSDC-USDC.png',
    name: 'maUSDC-USDC LP',
    token: 'maUSDC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x7295304b10740BA8e037826787d3e9386FD99925',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickmaUSDC-USDC',
    earnedTokenAddress: '0x8c2d54BA94f4638f1bb91f623F378B66d6023324',
    earnContractAddress: '0x8c2d54BA94f4638f1bb91f623F378B66d6023324',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-mausdc-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'QuickSwap',
    assets: ['maUSDC', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x9719d867A500Ef117cC201206B8ab51e794d3F82/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x9719d867a500ef117cc201206b8ab51e794d3f82',
  },
  {
    id: 'quick-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x90bc3E68Ba8393a3Bf2D79309365089975341a43',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickAAVE-ETH',
    earnedTokenAddress: '0x752948B4493d2Ee09c95F944A76005aEBF410087',
    earnContractAddress: '0x752948B4493d2Ee09c95F944A76005aEBF410087',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['AAVE', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-link-eth',
    logo: 'polygon/LINK-ETH.png',
    name: 'LINK-ETH LP',
    token: 'LINK-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x5cA6CA6c3709E1E6CFe74a50Cf6B2B6BA2Dadd67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickLINK-ETH',
    earnedTokenAddress: '0xaB4105375FbE5F502B0da986F46ADf7a21762e52',
    earnContractAddress: '0xaB4105375FbE5F502B0da986F46ADf7a21762e52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-link-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['LINK', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'krill-krill-usdc-eol',
    logo: 'polygon/KRILL-USDC.png',
    name: 'KRILL-USDC LP',
    token: 'KRILL-USDC LP',
    tokenDescription: 'Polywhale',
    tokenAddress: '0x6405Ebc22cB0899FC21f414085Ac4044B4721a0d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPolywhaleKRILL-USDC',
    earnedTokenAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    earnContractAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'krill-krill-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Polywhale',
    assets: ['KRILL', 'USDC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x05089c9ebffa4f0aca269e32056b1b36b37ed71b&outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  },
  {
    id: 'quick-eth-usdt',
    logo: 'polygon/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xF6422B997c7F54D1c6a6e103bcb1499EeA0a7046',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-USDT',
    earnedTokenAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    earnContractAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'USDT'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  {
    id: 'quick-eth-matic',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'ETH-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xadbF1854e5883eB8aa7BAf50705338739e558E5b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickETH-MATIC',
    earnedTokenAddress: '0x8b89477dFde285849E1B07947E25012206F4D674',
    earnContractAddress: '0x8b89477dFde285849E1B07947E25012206F4D674',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['ETH', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=ETH',
  },
  {
    id: 'quick-quick-matic',
    logo: 'polygon/QUICK-MATIC.png',
    name: 'QUICK-MATIC LP',
    token: 'QUICK-MATIC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x019ba0325f1988213D448b3472fA1cf8D07618d7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooQuickQUICK-MATIC',
    earnedTokenAddress: '0xa008B727ddBa283Ddb178b47BB227Cdbea5C1bfD',
    earnContractAddress: '0xa008B727ddBa283Ddb178b47BB227Cdbea5C1bfD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-quick-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['QUICK', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x831753dd7087cac61ab5644b308642cc1c33dc13',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13',
  },
  {
    id: 'cometh-eth-matic',
    logo: 'polygon/ETH-MATIC.png',
    name: 'ETH-MATIC LP',
    token: 'ETH-MATIC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x462390EA53F70F7570EEB2002c0150aF519f93A9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethETH-MATIC',
    earnedTokenAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    earnContractAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['ETH', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  },
  {
    id: 'cometh-matic-must',
    logo: 'polygon/MUST-MATIC.png',
    name: 'MUST-MATIC LP',
    token: 'MUST-MATIC LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x80676b414a905De269D0ac593322Af821b683B92',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethMUST-MATIC',
    earnedTokenAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    earnContractAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-matic-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['MUST', 'MATIC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
  },
  {
    id: 'cometh-usdc-must',
    logo: 'polygon/USDC-MUST.png',
    name: 'USDC-MUST LP',
    token: 'USDC-MUST LP',
    tokenDescription: 'Cometh',
    tokenAddress: '0x12A2aBcFcADc04681929F0c199bDf812967207E4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComethUSDC-MUST',
    earnedTokenAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    earnContractAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cometh-usdc-must',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Cometh',
    assets: ['USDC', 'MUST'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.cometh.io/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    buyTokenUrl:
      'https://swap.cometh.io/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
  },
];
