import React, { useMemo } from "react";
import SocialNetwork from "components/SocialNetwork";
import Countdown from "react-countdown";
import moment from "moment";


const getBuyingToken = (data) => {
  if (data.isBNB) {
    return "BNB";
  } else {
    return data.buyingToken.symbol.toUpperCase();
  }
};


const parseBigNumber=(number,decimal = 18)=>{
  if(number){
    return number.div(10**decimal).toFixed(2);

  }
}

const dateRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return;
  } else {
    // Render a countdown
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};

const getFencyDate = (date, isEnd) => {
  date = new Date(date).getTime();

  return (
    <div className="pp-card-col  ps-28">
      {isEnd ? "Finishes" : "Starts"}
      <br />
      <b>{moment(date).format("MMM-DD-YYYY hh:mm a")} </b>
    </div>
  );
};
const getStatusTag = (data) => {
  let text = "Upcoming";
  let classTag = "upcoming"; //closed open upcoming

  let startDate = new Date(data.startDate).getTime();
  let endDate = new Date(data.endDate).getTime();
  let now = new Date().getTime();
  if (now > startDate && now < endDate) {
    classTag = "open";
    text = "Open";
  } else if (now > startDate && now > endDate) {
    classTag = "closed";
    text = "Closed";
  }

  return (
    <span className={`pp-status-${classTag}`}>
      <i className="mdi mdi-circle " /> {text}
    </span>
  );
};



const SingleIDOItem = ({ data }) => {

  const getPercent = ()=>{
    return (parseInt(parseBigNumber(data.getRaisedAmount))/parseInt(parseBigNumber(data.target)))*100
  }
  console.log("saa",data.logo)
  return (
    <a href={`/ido/${data.tokenAddress}`} className="single-item">
      <div className="pp-card-body">
        <div className="pp-card-top">
          <a>
            <div className="icon-box">
              <span>
                <img src={data.logo} alt="#" />
              </span>
            </div>
          </a>
          <div className="title-box">
            <h3
              className="d-flex align-items-center"
              style={{ color: "#8639bc" }}
            >
              <a>
                <div>{data.name}</div>
              </a>
            </h3>
            <SocialNetwork {...data.socialMedia} />

            <div className="items-morex">
              {getStatusTag(data)}
              <span className="pp-status-currency">
                <i className="mdi mdi-circle " /> {getBuyingToken(data)}
              </span>
            </div>
          </div>
        </div>
        <div className="item-desc mb-1">
          <div className="item-short-desc" style={{ color: "#fff" }}>
            {data.description}
          </div>
          <div className="item-learn-more" />
        </div>
        <div className="part-prize">
          <div className="pp-card-info mb-3">
           
            <div className="pp-card-col text-center ps-28">
              Target
              <br />
              <b>{parseBigNumber(data.target)}  {getBuyingToken(data)}</b>
            </div>
            <div className="pp-card-col text-end">
              Access
              <br />
              <b>Tier Based</b>
            </div>
          </div>

          <div className="pp-card-info mb-3">
           
          <div className="pp-card-col">
             Tier1 Max Contribution
              <br />
              <b className="nowrap">{parseBigNumber(data.tier1maxContribution)} {getBuyingToken(data)}</b>
            </div>

            <div className="pp-card-col">
             Tier2 Max Contribution
              <br />
              <b className="nowrap">{parseBigNumber(data.tier2maxContribution)} {getBuyingToken(data)}</b>
            </div>
         </div>

        

          <div className="pp-card-progress-wrap">
            <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
              <div className="pp-card-col">Progress</div>
              {/* <div className="pp-card-col text-end">
                Participants <b className="text-participants font-12">3148</b>
              </div> */}
            </div>
            <div className="pp-card-progress">
              <div
                title={9}
                className="pp-card-progress-percent"
                style={{ width: `${getPercent()}%` }}
              />
              <div className="pp-card-progress-label">
                <span>
                  <b>{`${getPercent()}%`}</b>
                </span>
                <span className="text-allocation">
                  <b className>{parseBigNumber(data.getRaisedAmount)}/{parseBigNumber(data.target)} {getBuyingToken(data)}</b>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="pp-card-info mb-3 mt-5">
          {getFencyDate(data.startDate, false)}
          {getFencyDate(data.endDate, true)}

          <div />
        </div>
      </div>
    </a>
  );
};

export default SingleIDOItem;
