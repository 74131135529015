import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BigNumber from 'bignumber.js';
import { MultiCall } from 'eth-multicall';
import config  from 'config';
import IDOS from "config/idos"
import {
  VAULT_FETCH_VAULTS_DATA_BEGIN,
  VAULT_FETCH_VAULTS_DATA_SUCCESS,
  VAULT_FETCH_VAULTS_DATA_FAILURE,
} from './constants';
import { fetchPrice, whenPricesLoaded } from '../../web3';
import { erc20ABI, vaultABI,multicallABI,TOKEN_IDO,BNB_IDO } from '../../configure';
import { byDecimals } from 'features/helpers/bignumber';
import { getNetworkMulticall } from 'features/helpers/getNetworkData';
import Web3 from 'web3';
import { getRpcUrl } from 'common/networkSetup';
import axios from 'axios';




export function fetchIDOData({address, web3 }) {
  return async(dispatch) => {
    dispatch({
      type: VAULT_FETCH_VAULTS_DATA_BEGIN,
    });

    let idos = IDOS

  //  let response  = await axios.get(`${config.BASE_API_URL}/ido/`);

  //  if (response.data.code === 200) {
    
  // } else {
  //   alert(response.data.message)
  // }

    if (!web3) {
      web3 = new Web3(new Web3.providers.HttpProvider(getRpcUrl()));
    }

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, getNetworkMulticall());
      let idoContract;
      const vaultCalls = idos.map(ido => {
        idoContract = new web3.eth.Contract(BNB_IDO, ido.idoContract);

        if(!ido.isBNB){
          idoContract = new web3.eth.Contract(TOKEN_IDO, ido.idoContract);
        }

        
        let calls = []
        calls.push(idoContract.methods.tier1MaxContribution())
        calls.push(idoContract.methods.tier2MaxContribution())

        calls.push(idoContract.methods.target())
        calls.push(idoContract.methods.getRaisedAmount())

        calls.push(idoContract.methods.startDate())
        calls.push(idoContract.methods.endDate())









        if(address){
          calls.push(idoContract.methods.getTier(address))
          calls.push(idoContract.methods.participations(address))
        }


    

        return calls
      });


      



      
      multicall
      .all([vaultCalls])
        .then(data => data[0])
        .then(data => {

          const newPools = idos.map((ido, i) => {

           

            console.log("idosss",data)
            ido.logo = `/idos/${ido.name.toLowerCase()}.png`
            

            if(data[i]){
              // ido.maxContribution =  new BigNumber(data[i][0])

              ido.tier1maxContribution =  new BigNumber(data[i][0])
              ido.tier2maxContribution =  new BigNumber(data[i][1])

              ido.target = new BigNumber(data[i][2])
              ido.getRaisedAmount =  new BigNumber(data[i][3])
              ido.startDate =   Number(data[i][4])*1000
              ido.endDate =   Number(data[i][5])*1000;
              ido.contractObject = idoContract



              // if(data[i][4]){
              //   console.log("data[i][3]",data[i][4])
              //   if(parseInt(data[i][4]) ==0){
              //     ido.myTier = "No Tier"
              //   }else{
              //     ido.myTier = data[i][4]
              //   }
               
              // }

              // if(data[i][5]){
              //   ido.myParticipation =  new BigNumber(data[i][5])
              // }
            }

         
            return ido
          });


          dispatch({
            type: VAULT_FETCH_VAULTS_DATA_SUCCESS,
            data: newPools,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_VAULTS_DATA_FAILURE,
          });
          console.log("ewwww",error)
          reject(error.message || error);
        });
    });

    return promise
  };
}



export function useFetchIDOData() {
  const dispatch = useDispatch();

  const { idos, fetchIDODataDone } = useSelector(
    state => ({
      idos: state.vault.pools,
      fetchIDOData: state.vault.fetchIDOData,
      fetchIDODataDone: state.vault.fetchIDODataDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchIDOData(data));
    },
    [dispatch]
  );

  return {
    idos,
    fetchIDOData: boundAction,
    fetchIDODataDone,
  };
}




export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_VAULTS_DATA_BEGIN:
      return {
        ...state,
        fetchIDODataPending: true,
      };

    case VAULT_FETCH_VAULTS_DATA_SUCCESS:
      const pools = action.data.map((pool, i) => ({
        ...pool,
        ...action.data[i],
      }));
      console.log("asasaassas",pools)

      return {
        ...state,
        pools,
        fetchIDODataPending: false,
        fetchIDODataDone: true,
      };

    case VAULT_FETCH_VAULTS_DATA_FAILURE:
      return {
        ...state,
        fetchIDODataPending: false,
      };

    default:
      return state;
  }
}
