import React, { useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

import IDO from '../IDO/IDO';
import { Accordion, Grid } from '@material-ui/core';

const useStyles = makeStyles(styles);

const VisibleIDOs = ({
  idos,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  
  

  // useEffect(() => {
  //   fetchPoolData(indexes);
  // }, [fetchPoolData]);

  return (
    <>
   
      <div className={classes.scroller}  style={{overflow:"auto", paddingBottom:"100px"}}>
        <InfiniteScroll dataLength={idos.length} hasMore={true}>
          <Grid container  style={{overflowY:"auto"}}>
            {idos.map((ido, index) => (
              <Grid xl={4} sm={12} md={4} style={{ minWidth: "290px", margin:"auto" }}>
                
                  <IDO
                    ido={ido}
                  />
              </Grid>

            ))}
          </Grid>
        </InfiniteScroll>
      </div>
      
      {/* {!sortedPools.length && <h3 className={classes.subtitle}>{t('No-Results')}</h3>} */}
    </>
  );
};

export default VisibleIDOs;
