import React from 'react';
import { useParams } from 'react-router';

import IDODetails from './components/PoolDetails/IDODetails';

export default function IDODetailsPage() {
  const { vaultId } = useParams();
  return (
    <>
      <IDODetails vaultId={vaultId} page="vault"  />
    </>
  );
}
