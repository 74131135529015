import React, { useEffect, useRef, useState } from 'react';
import { renderIcon } from '@download/blockies';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { WbSunny, NightsStay } from '@material-ui/icons';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';
import { useTranslation } from 'react-i18next';

import styles from './styles';

const useStyles = makeStyles(styles);

const HeaderLinks = ({
  connected,
  address,
  connectWallet,
  disconnectWallet,
  isNightMode,
  setNightMode,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [lng, setLanguage] = useState('en');
  const [shortAddress, setShortAddress] = useState('');
  const [dataUrl, setDataUrl] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const cachedLanguage = i18n.language;
    if (!cachedLanguage) {
      return;
    }

    const languageCode = cachedLanguage.split('-')[0].toLowerCase();
    setLanguage(languageCode);
  }, [i18n.language]);

  useEffect(() => {
    if (!connected) {
      return;
    }

    const canvas = canvasRef.current;
    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }
  }, [dataUrl, address, connected]);

  const handleClick = event => {
    switch (event) {
      case 'Deutsch':
        return i18n.changeLanguage('de').then(() => setLanguage(event));
      case 'Ελληνικά':
        return i18n.changeLanguage('el').then(() => setLanguage(event));
      case 'English':
        return i18n.changeLanguage('en').then(() => setLanguage(event));
      case 'Español':
        return i18n.changeLanguage('es').then(() => setLanguage(event));
      case 'Français':
        return i18n.changeLanguage('fr').then(() => setLanguage(event));
      case 'हिन्दी':
        return i18n.changeLanguage('hi').then(() => setLanguage(event));
      case 'Bahasa Indonesia':
        return i18n.changeLanguage('id').then(() => setLanguage(event));
      case 'Italiano':
        return i18n.changeLanguage('it').then(() => setLanguage(event));
      case '한글':
        return i18n.changeLanguage('ko').then(() => setLanguage(event));
      case 'Nederlands':
        return i18n.changeLanguage('nl').then(() => setLanguage(event));
      case 'Português':
        return i18n.changeLanguage('pt').then(() => setLanguage(event));
      case 'Pусский':
        return i18n.changeLanguage('ru').then(() => setLanguage(event));
      case 'Svenska':
        return i18n.changeLanguage('se').then(() => setLanguage(event));
      case 'Türkçe':
        return i18n.changeLanguage('tr').then(() => setLanguage(event));
      case '中文':
        return i18n.changeLanguage('zh').then(() => setLanguage(event));
      case 'Українська':
        return i18n.changeLanguage('uk').then(() => setLanguage(event));
      // TODO: more translations
      default:
        return;
    }
  };


  const WalletImage = () => {
    return (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.44014 3.85252L12.0145 1.78503L11.6134 0.974244C11.351 0.447237 10.7109 0.229606 10.1839 0.492043L3.4032 3.85252H4.44014Z" fill="white" />
        <path d="M14.3657 1.84473C14.2718 1.84473 14.1779 1.85753 14.084 1.88313L12.3046 2.3696L6.87024 3.85248H13.0386H15.7269L15.3941 2.63204C15.2661 2.15624 14.8351 1.84473 14.3657 1.84473Z" fill="white" />
        <path d="M16.8386 4.5993H16.5954H16.2647H15.934H13.4099H4.13498H2.91881H1.89467H1.70477H1.06895C0.731836 4.5993 0.430994 4.75506 0.2347 5.00043C0.145087 5.11351 0.0768108 5.24366 0.0384054 5.38661C0.0149354 5.47623 0 5.57011 0 5.66612V5.79414V7.01031V17.5526C0 18.1415 0.477934 18.6194 1.06682 18.6194H16.8365C17.4254 18.6194 17.9033 18.1415 17.9033 17.5526V14.5762H11.5728C10.5722 14.5762 9.75924 13.7632 9.75924 12.7626V11.7854V11.4547V11.1239V10.39C9.75924 9.89924 9.95551 9.45334 10.2734 9.12689C10.5551 8.8367 10.9327 8.6404 11.3552 8.59133C11.4256 8.58281 11.4981 8.57853 11.5707 8.57853H17.0157H17.3464H17.6771H17.9033V5.66612C17.9054 5.07724 17.4275 4.5993 16.8386 4.5993Z" fill="white" />
        <path d="M18.6523 9.59839C18.5456 9.50025 18.4198 9.42557 18.2789 9.3765C18.1701 9.34025 18.0549 9.31891 17.9333 9.31891H17.9055H17.8842H17.5535H16.3608H11.5729C10.984 9.31891 10.5061 9.79682 10.5061 10.3857V10.917V11.2477V11.5784V12.7604C10.5061 13.3493 10.984 13.8273 11.5729 13.8273H17.9055H17.9333C18.0549 13.8273 18.1701 13.8059 18.2789 13.7696C18.4198 13.7227 18.5456 13.6459 18.6523 13.5477C18.8657 13.3536 19.0001 13.0719 19.0001 12.7605V10.3857C19.0001 10.0742 18.8657 9.79253 18.6523 9.59839ZM13.8004 11.7854C13.8004 12.0798 13.5615 12.3188 13.267 12.3188H12.9128C12.6184 12.3188 12.3794 12.0798 12.3794 11.7854V11.4312C12.3794 11.2605 12.4584 11.109 12.5843 11.013C12.676 10.9426 12.7891 10.8978 12.9128 10.8978H13.0025H13.267C13.5615 10.8978 13.8004 11.1367 13.8004 11.4312V11.7854Z" fill="white" />
      </svg>

    )
  }
  return (
    <List className={classes.list + ' ' + classes.mlAuto}>
      {/* <Hidden smDown>
        <ListItem className={classes.listItem}>
          <IconButton onClick={setNightMode} className={classes.iconButton}>
            {isNightMode ? <WbSunny /> : <NightsStay />}
          </IconButton>
        </ListItem>
      </Hidden> */}
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          navDropdown
          hoverColor="dark"
          buttonText={lng}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          onClick={handleClick}
          dropdownList={[
            'Deutsch',
            'English',
            'Ελληνικά',
            'Español',
            'Français',
            'हिन्दी',
            'Bahasa Indonesia',
            'Italiano',
            '한글',
            'Nederlands',
            'Português',
            'Pусский',
            'Svenska',
            'Türkçe',
            'Українська',
            '中文',
            // TODO: more translations
            { divider: true },
            <a
              href="https://github.com/beefyfinance/beefy-app/tree/master/src/locales"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.cta}
            >
              Help to translate
            </a>,
          ]}
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>

        <Button
          onClick={()=>{
            window.open("https://pancakeswap.finance/swap?outputCurrency=0xede26a1ee14281b58a5238a3ff246b02358a13b6", '_blank').focus();

          }}
          style={{ backgroundColor: "#8538bd", borderRadius: "20px", marginRight: "10px", fontSize: "16px", fontWeight: "bold" }}
        >
          Buy KITE
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          disableElevation
          className={classes.walletDisplay}
          onClick={connected ? disconnectWallet : connectWallet}
          style={{ backgroundColor: "#0b0e30", border: "1px solid white", borderRadius: "20px" }}
        >
          {connected ? (
            <>
              <canvas ref={canvasRef} style={{ display: 'none' }} />
              {/* <Avatar
                alt="address"
                src={dataUrl}
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '4px',
                }}
              /> */}
              {shortAddress}
            </>
          ) : (
            <div>
              {/* <i className={classes.icon + ' far fa-question-circle'} /> */}
              {/* {t('Vault-Wallet')} */}
              <WalletImage />
              Unlock Wallet
            </div>
          )}
        </Button>
      </ListItem>
    </List>
  );
};

export default HeaderLinks;
