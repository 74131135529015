import React, { useEffect, useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { erc20ABI, vaultABI,TOKEN_IDO,BNB_IDO } from "../../../configure";
import { byDecimals } from "features/helpers/bignumber";

import LoadingLottie from "components/LoadingLottie";
import NotFoundLottie from "components/NotFoundLottie";

import { Container, Row, Col } from "react-bootstrap";

import { useConnectWallet } from "../../../home/redux/hooks";

import HomeLink from "./HomeLink/HomeLink";

import styles from "./styles";
import { Helmet } from "react-helmet";
import { usePageMeta } from "../../../common/getPageMeta";
import SocialNetwork from "components/SocialNetwork";
import {
  useFetchBalances,
  useFetchIDOData,
  useFetchApys,
} from "../../../vault/redux/hooks";
import { useParams } from "react-router";
import moment from "moment";
import BigNumber from "bignumber.js";
import { ExpandLessSharp } from "@material-ui/icons";

const FETCH_INTERVAL_MS = 15 * 1000;

const IDODetails = (props) => {
  const { web3, address } = useConnectWallet();
  const { idos, fetchIDOData, fetchIDODataPending, fetchIDODataDone } =
    useFetchIDOData();
  const { idoId } = useParams();

  const [buyingTokenContract, setBuyingTokenContract] = useState();
  const [idoContract, setIdoContract] = useState();

  const [inputAmount,setInputAmount] = useState()
  const [isNotFound, setIsNotFound] = useState(true);
  const [ido, setIdo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [buyingTokenDetails, setBuyingTokenDetails] = useState({
    balance: "-",
    allowance: "-",
  });

  useEffect(() => {
    const fetch = () => {
      // if (address && web3 && !fetchBalancesPending) {
      //   fetchBalances({ address, web3, tokens });
      // }

      if (!fetchIDODataPending) {
        fetchIDOData({ web3, address });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchIDOData]);

  // const rout
  const { getPageMeta } = usePageMeta();

  useEffect(() => {
    if (fetchIDODataDone) {
      let isNotFound = true;

      for (let item of idos) {
        if (item.tokenAddress.toLowerCase() === idoId.toLowerCase()) {
          isNotFound = false;
          setIdo(item);
        }
      }

      setIsNotFound(isNotFound);
    }
  }, [fetchIDODataDone]);

  const getBuyingToken = (data) => {
    if (data) {
      if (data.isBNB) {
        return "BNB";
      } else {
        return data.buyingToken.symbol.toUpperCase();
      }
    }
  };

  const parseBigNumber = (number, decimal = 18) => {
    if(number){
      return number.div(10 ** decimal).toFixed(2);

    }
  };


  const handleParticipate =async ()=>{
    if(inputAmount){
      setIsLoading(true)
      let _inputAmount = web3.utils.toWei(inputAmount);

      try{
        if(ido.isBNB){
          await idoContract.methods.participate(_inputAmount).send({from:address,value:_inputAmount});

        }else{
          await idoContract.methods.participate(_inputAmount).send({from:address});

        }

        setInputAmount("")
        loadData()

      }catch(err){
        console.log("saasasasa",err)
      }

      setIsLoading(false)

    }else{
      alert("Please Enter Amount")
    }
  }

  const getStatusTag = (data) => {
    let text = "Upcoming";
    let classTag = "upcoming"; //closed open upcoming

    let startDate = new Date(data.startDate).getTime();
    let endDate = new Date(data.endDate).getTime();
    let now = new Date().getTime();
    if (now > startDate && now < endDate) {
      classTag = "open";
      text = "Open";
    } else if (now > startDate && now > endDate) {
      classTag = "closed";
      text = "Closed";
    }

    return (
      <span className={`pp-status-${classTag}`}>
        <i className="mdi mdi-circle " /> {text}
      </span>
    );
  };

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      await buyingTokenContract.methods
        .approve(ido.idoContract, web3.utils.toWei("8000000000", "ether"))
        .send({ from: address });
    } catch (e) {}
    setIsLoading(false);

    loadData();
  };
  const renderButton = () => {
    if (address) {

      if( isNaN(ido.myTier)){
        return (
          <button
            type="button"
            disabled
           
            style={{
              width: "100%",
              fontWeight: "bold",
              color: "#000",
            }}
            className="btn btn-primary me-2"
          >
            You are not Eligible
          </button>
        );
      }else{

      if (buyingTokenDetails.allowance > 0) {
        return (
          <>

          <input placeholder="Enter Amount" 
          type="number"
          onChange={(e)=>{
            setInputAmount(e.target.value)
          }}
          value={inputAmount}
          style={{width:"100%",
          background:"transparent",
          color:"#fff",
          border:"2px solid #24043c",
          borderRadius:10,
          padding:6,
          marginTop:10,
          marginBottom:10,
          fontWeight:"bolder",fontSize:18}}/>
            <button
            onClick={()=>{
              handleParticipate()
            }}
              type="button"
              style={{
              
                width: "100%",
                fontWeight: "bold",
                color: "#000",
              }}
              className="btn btn-primary me-2"
            >
              {isLoading ? (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                inputAmount?`Pay ${inputAmount} ${getBuyingToken(ido)}` :"Participate"
              )}
            </button>
          </>
        );
      } else {
        return (
          <button
            type="button"
            onClick={() => {
              handleApprove();
            }}
            style={{
              width: "100%",
              fontWeight: "bold",
              color: "#000",
            }}
            className="btn btn-primary me-2"
          >
            {isLoading ? (
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              `Approve ${getBuyingToken(ido)}`
            )}
          </button>
        );
      }
   
   
      }
   
   
    } else {
      return (
        <button
          type="button"
          style={{
            width: "100%",
            fontWeight: "bold",
            color: "#000",
          }}
          className="btn btn-primary me-2"
        >
          Connect Wallet
        </button>
      );
    }
  };


  const getPercent = ()=>{
    return ((parseInt(parseBigNumber(ido.getRaisedAmount))/parseInt(parseBigNumber(ido.target)))*100).toFixed(2)
  }

  const renderMainData = () => {
    if (isNotFound) {
      return <NotFoundLottie />;
    }



   
    return (
      <div className="pp-detail-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5">
              <div className="pp-card-top animation">
                <span>
                  <img
                    src={ido.logo}
                    style={{ height: 100, width: 100, borderRadius: "50%" }}
                  />
                </span>
              </div>
              <div className="ms-0">
                <div className="pp-card-top">
                  <div className="title-box">
                    <h2>{ido.name}</h2>
                    <h2>
                      <SocialNetwork {...ido.socialMedia} />

                      <div className="items-morex">
                        {getStatusTag(ido)}

                        <span className="pp-status-currency">
                          {getBuyingToken(ido)}
                        </span>
                      </div>
                    </h2>
                  </div>
                </div>
                <div className="mb-3">
                  <p className="text-white">{ido.description}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-7">
              <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0 gradient-bg">
                <div className="card-body px-3 py-2">
                  <div className="pp-card-info mt-2">
                    <div className="pp-card-col tc-white">
                      Your balance
                      <br />
                      <div className="d-flex justify-content-between align-items-center tc-white">
                        <h4 className="mb-0">
                          <strong id="idBusdBalance">
                            {buyingTokenDetails.balance} {getBuyingToken(ido)}
                          </strong>
                        </h4>
                      </div>
                    </div>
                    <div className="pp-card-col tc-white">
                      Your Eligible Tier
                      <br />
                      <h4 className="mb-0">{ido.myTier ? ido.myTier : "-"}</h4>
                    </div>
                 
                  </div>

                  <div className="pp-card-info mt-2">
                  <div className="pp-card-col tc-white">
                      Your Tier 1 Contribution
                      <br />
                      <div className="d-flex justify-content-between align-items-center tc-white">
                        <h4 className="mb-0">
                          <strong id="idBusdBalance">
                            {ido.myTier1Participation
                              ? ido.myTier1Participation
                              : null}{" "}
                            {getBuyingToken(ido)}
                          </strong>
                        </h4>
                      </div>
                    </div>
                    <div className="pp-card-col tc-white">
                      Your Tier 2 Contribution
                      <br />
                      <div className="d-flex justify-content-between align-items-center tc-white">
                        <h4 className="mb-0">
                          <strong id="idBusdBalance">
                            {ido.myTier2Participation
                              ? ido.myTier2Participation
                              : null}{" "}
                            {getBuyingToken(ido)}
                          </strong>
                        </h4>
                      </div>
                    </div>




                      <div className="pp-card-col tc-white">
                      Your Tier 3 Contribution
                      <br />
                      <div className="d-flex justify-content-between align-items-center tc-white">
                        <h4 className="mb-0">
                          <strong id="idBusdBalance">
                            {ido.myTier3Participation
                              ? ido.myTier3Participation
                              : null}{" "}
                            {getBuyingToken(ido)}
                          </strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <hr className="mb-2" />

                  <div className="pp-card-info">
                    <div className="d-flex justify-content-between w-100">
                      <div className="pp-card-col">
                        Max Contribution
                        <br />
                        <b id="idUseParticipation">
                          {parseBigNumber(ido.maxContribution)}{" "}
                          {getBuyingToken(ido)}
                        </b>
                      </div>
                      <div className="pp-card-col w-200px">
                        Target
                        <br />
                        <b id="idBusdMaxBuy">
                          {parseBigNumber(ido.target)} {getBuyingToken(ido)}
                        </b>
                      </div>

                      <div className="pp-card-col w-200px">
                        Access
                        <br />
                        <b id="idBusdMaxBuy">Tier Based</b>
                      </div>
                    </div>
                  </div>

                  <div className="pp-card-info">
               
                    <div className="d-flex justify-content-between w-100">

                    <div className="pp-card-col">
                    Total Raised
                        <br />
                        <b id="idUseParticipation">
                        {parseBigNumber(ido.getRaisedAmount)}{" "}
                        {getBuyingToken(ido)}
                        </b>
                      </div>
                      <div className="pp-card-col">
                        Started At
                        <br />
                        <b id="idUseParticipation">
                          {moment(ido.startDate).format("MMM-DD-YYYY hh:mm a")}
                        </b>
                      </div>
                      <div className="pp-card-col w-200px">
                        Ends in
                        <br />
                        <b id="idBusdMaxBuy">
                          {moment(ido.endDate).format("MMM-DD-YYYY hh:mm a")}{" "}
                        </b>
                      </div>
                    </div>
                  </div>

                  <hr className="mb-2 mt-2" />
                  <div className="pp-card-info">
                    <div className="d-flex justify-content-between w-100">
                      <div className="pp-card-col w-200px">
                        Status
                        <br />
                        <div className="pp-card-progress">
                          <div
                            className="pp-card-progress-percent"
                            style={{ width: `${getPercent()}%` }}
                          />
                          <div className="pp-card-progress-label">
                            <span>
                              <b>{`${getPercent()}%`}</b>
                            </span>
                            <span>{parseBigNumber(ido.getRaisedAmount)}/{parseBigNumber(ido.target)}{" "} {getBuyingToken(ido)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>{renderButton()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (ido && address) {
      loadData();
    }
  }, [ido, address]);

  const getERC20Contract = (address) => {
    return new web3.eth.Contract(erc20ABI, address);
  };

  const loadData = async () => {

    let _idoContract = null;

    if (ido.isBNB) {
      _idoContract = new web3.eth.Contract(BNB_IDO,ido.idoContract);
      let buyingTokenBalance = await web3.eth.getBalance(address);
      buyingTokenBalance = byDecimals(buyingTokenBalance, 18)
        .toNumber()
        .toFixed(4);
      let buyingTokenAllowance = 1000000000000000000;

      setBuyingTokenDetails({
        balance: buyingTokenBalance,
        allowance: buyingTokenAllowance,
      });
    } else {
      let buyingTokenContract = getERC20Contract(ido.buyingToken.address);
      _idoContract = new web3.eth.Contract(TOKEN_IDO,ido.idoContract);

      setBuyingTokenContract(buyingTokenContract);
      let buyingTokenBalance = await buyingTokenContract.methods
        .balanceOf(address)
        .call();
      buyingTokenBalance = byDecimals(
        buyingTokenBalance,
        ido.buyingToken.decimals
      )
        .toNumber()
        .toFixed(4);

      let buyingTokenAllowance = await buyingTokenContract.methods
        .allowance(address, ido.idoContract)
        .call();

      setBuyingTokenDetails({
        balance: buyingTokenBalance,
        allowance: buyingTokenAllowance,
      });
    }

    loadMyIdoData(_idoContract,address)

    setIdoContract(_idoContract)

  };

  const loadMyIdoData = async(contract,account)=>{
    let _ido = ido;

      let myTier = await contract.methods.getTier(account).call()
      if(myTier == 0){
        _ido.myTier = "Not Eliglible";
 
      }else{
        _ido.myTier = Number(myTier);
      }

      let myParticipation = await contract.methods.participations(account).call()

      let myTier1Participation = (Number(myParticipation.tierOneAmount)/1e18).toFixed(2)
      let myTier2Participation = (Number(myParticipation.tierTwoAmount)/1e18).toFixed(2)
      let myTier3Participation = (Number(myParticipation.tierThreeAmount)/1e18).toFixed(2)

      console.log("myTier1Participation+myTier2Participation+myTier3Participation",myTier1Participation,myTier2Participation,myTier3Participation)
      let myAllParticipation = Number(myTier1Participation)+Number(myTier2Participation)+Number(myTier3Participation)
      myAllParticipation = myAllParticipation.toFixed(2)

      _ido.myTier1Participation  = myTier1Participation
      _ido.myTier2Participation  = myTier2Participation
      _ido.myTier3Participation  = myTier3Participation
      _ido.myAllParticipation  = myAllParticipation

      console.log("myPart",myParticipation)
      setIdo(_ido)
  }

  if (!idoId) {
    return <NotFoundLottie />;
  }

  return (
    <Grid xs={12} md={12} sm={12} xl={10} lg={10}>
      <Helmet>
        <title>
          {getPageMeta("Vault-Meta-Title", {
            vaultName: ido &&ido.name?ido.name:"",
            vaultDescription:ido &&ido.description?ido.description:"",
          })}
        </title>
        <meta
          property="og:title"
          content={getPageMeta("Vault-Meta-Title", {
            vaultName: "sds",
            vaultDescription: "DSd",
          })}
        />
      </Helmet>
      <HomeLink />
      <Container
        style={{
          border: "2px solid #8639bc",
          padding: 15,
          background: "#00000057",
          borderRadius: 10,
        }}
      >
        {fetchIDODataDone ? renderMainData() : <LoadingLottie />}
      </Container>
    </Grid>
  );
};

export default IDODetails;
