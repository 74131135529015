import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';
import { Grid } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();


  const Twitter = () => {
    return (
      <svg width="23" style={{verticalAlign:"bottom"}} height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.6819 4.49275C20.6819 4.69565 20.6819 4.89855 20.6819 5.07246C20.6819 11.0725 15.9254 18 7.25524 18C4.57592 18 2.10733 17.2464 0 15.971C0.361257 16 0.752618 16.029 1.11387 16.029C3.31152 16.029 5.35864 15.3043 6.98429 14.087C4.90707 14.058 3.16099 12.7246 2.58901 10.9275C2.89005 10.9855 3.16099 11.0145 3.49215 11.0145C3.91361 11.0145 4.33508 10.9565 4.72644 10.8406C2.5589 10.4348 0.933246 8.6087 0.933246 6.4058C0.933246 6.37681 0.933246 6.37681 0.933246 6.34783C1.56544 6.69565 2.28796 6.89855 3.07068 6.92754C1.80628 6.11594 0.963351 4.72464 0.963351 3.13043C0.963351 2.28986 1.20419 1.50725 1.59555 0.84058C3.91361 3.5942 7.40576 5.3913 11.3194 5.5942C11.2291 5.27536 11.199 4.89855 11.199 4.55072C11.199 2.02899 13.3063 0 15.9254 0C17.2801 0 18.5144 0.550725 19.3573 1.42029C20.4411 1.21739 21.4346 0.84058 22.3678 0.318841C22.0065 1.3913 21.2539 2.26087 20.2906 2.84058C21.2539 2.72464 22.1571 2.49275 23 2.11594C22.3979 3.04348 21.6152 3.85507 20.6819 4.49275Z" fill="#5F5F6E" />
      </svg>

    )
  }

  const Vk = () => {
    return (
      <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.3576 1.08337C27.5523 0.458948 27.3576 0 26.4312 0H23.368C22.589 0 22.2302 0.396421 22.0355 0.833684C22.0355 0.833684 20.4776 4.48695 18.2708 6.86C17.5568 7.54695 17.2323 7.76526 16.8429 7.76526C16.6481 7.76526 16.3663 7.54695 16.3663 6.92253V1.08337C16.3663 0.333896 16.1403 0 15.4911 0H10.6775C10.1909 0 9.89812 0.34779 9.89812 0.677474C9.89812 1.388 11.0013 1.55179 11.1151 3.55011V7.89053C11.1151 8.84211 10.9366 9.01474 10.5471 9.01474C9.50866 9.01474 6.98282 5.34484 5.48447 1.14589C5.19084 0.329684 4.89633 0 4.11346 0H1.05025C0.175041 0 0 0.396421 0 0.833684C0 1.61432 1.03865 5.48632 4.8355 10.6072C7.36703 14.104 10.9331 16 14.1783 16C16.1256 16 16.3663 15.5789 16.3663 14.8537V12.2105C16.3663 11.3684 16.5508 11.2004 17.1671 11.2004C17.6218 11.2004 18.4005 11.4189 20.2181 13.1051C22.295 15.1032 22.6372 16 23.8056 16H26.8688C27.744 16 28.1816 15.5789 27.9291 14.748C27.653 13.9198 26.6613 12.7185 25.3455 11.2941C24.6315 10.4821 23.5605 9.60779 23.2362 9.17074C22.7818 8.60863 22.9115 8.35895 23.2362 7.85958C23.2362 7.85958 26.9681 2.80105 27.3576 1.08358V1.08337Z" fill="#5F5F6E" />
      </svg>

    )
  }

  const Telegram = () => {
    return (
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1822 17.8522C16.3804 18.3988 16.8297 17.2534 16.8297 17.2534L20 0.64532C19.975 -0.474036 18.5272 0.202783 18.5272 0.202783L0.778531 7.46558C0.778531 7.46558 -0.0702092 7.77796 0.00467962 8.32462C0.0795685 8.87129 0.753568 9.1316 0.753568 9.1316L5.22194 10.6935C5.22194 10.6935 6.56993 15.3011 6.84453 16.1861C7.09416 17.0452 7.31882 17.0712 7.31882 17.0712C7.56845 17.1753 7.79312 16.9931 7.79312 16.9931L10.6888 14.2598L15.1822 17.8522ZM15.9562 3.58681C15.9562 3.58681 16.5803 3.19634 16.5553 3.58681C16.5553 3.58681 16.6551 3.63888 16.3306 4.00332C16.0311 4.3157 8.96656 10.9277 8.01797 11.8128C7.94308 11.8648 7.89315 11.9429 7.89315 12.0471L7.61856 14.494C7.56863 14.7543 7.24411 14.7804 7.16922 14.5461L5.99597 10.5372C5.94604 10.381 5.99597 10.1988 6.14574 10.0947L15.9562 3.58681Z" fill="#5F5F6E" />
      </svg>

    )
  }

  const navigate = (url )=>{
    window.open(url, '_blank').focus();

  }
  return (


    <div style={{ position: "absolute", bottom: "0", width: "100%", padding: "10px", background: "#0B0E30", minHeight: "70px" }}>
      <Grid container>
        <Grid md="6" sm="12" style={{ textAlign: "center" }}>
          <p style={{ color: "#5F5F6E" }}>© 2021 Kite Sync. All rights reserved.</p>
        </Grid>
        <Grid md="6" sm="12" style={{ textAlign: "center" }}>


          <div style={{ display: "flex", flexFlow: "row  wrap", marginTop:"15px", justifyContent:"center" }}>

            <div style={{marginRight:"10px",cursor:"pointer"}} onClick={()=>{
             navigate("https://twitter.com/Kite_sync")
            }}>
                <Twitter />
            </div>


            <a style={{marginRight:"10px",cursor:"pointer"}} >
                <Telegram  onClick={()=>{
             navigate("https://t.me/kitesync")
            }}/>
            </a>

          </div>
          {/* <p style={{ color: "#5F5F6E" }}>© 2021 Kite Sync. All rights reserved.</p> */}
        </Grid>
      
      
      </Grid>
    </div>
  
  );
};

export default memo(Footer);
