import { Grid } from '@material-ui/core'
import React from 'react'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import deposit from './Group.png';
import buy from './Man.png';
import withdraw from './Woman.png'


function PoolPage() {
    return (
        <div style={{ paddingBottom: "200px" }}>
            <ScrollToTop />
            <Grid container>
                <Grid md={6} xs={12}>
                    <h1 style={{ color: "white" }}>Overview</h1>
                    <Grid container>
                        <Grid item style={{ padding: "10px", margin: "auto" }}>
                            <div style={{ borderRadius: "15px", width: "100%", minWidth: "320px", padding: "5px", border: "2px solid #8538BD", margin: "auto", background: "#191D59" }}>
                                <h3 style={{ color: "white", marginLeft: "10px" }}>$ 2562.00</h3>
                                <p style={{
                                    color: "#8385A5", marginLeft: "10px", marginTop: "-20px"
                                }}>Net Worth</p>
                            </div>
                        </Grid>
                        <Grid item style={{ padding: "10px", margin: "auto" }}>
                            <div style={{ borderRadius: "15px", width: "100%", minWidth: "320px", padding: "5px", border: "2px solid #8538BD", margin: "auto", background: "#191D59" }}>
                                <h3 style={{ color: "white", marginLeft: "10px" }}>$ 2562.00</h3>
                                <p style={{
                                    color: "#8385A5", marginLeft: "10px", marginTop: "-20px"
                                }}>Net Worth</p>
                            </div>
                        </Grid>
                    </Grid>

                    <h1 style={{ color: "white" }}>Your Position</h1>
                    <Grid container>
                        <Grid item style={{ padding: "20px", borderRadius: "15px", width: "100%", minWidth: "320px", maxWidth: "320px", background: "#191D59", margin: "auto" }}>
                            <div >
                                <h3 style={{ color: "white", marginLeft: "10px" }}>$ 0.00</h3>
                                <p style={{
                                    color: "#8385A5", marginLeft: "10px", marginTop: "-20px"
                                }}>{"<"}0.001 ( INST-ETH )</p>
                            </div>
                            <Grid container style={{ background: "#8538BD", borderRadius: "10px", padding: "20px" }}>
                                <Grid item style={{ width: "50%", borderWidth: "0px 1px 0px 0px", borderColor: "white", borderStyle: "solid", textAlign: "center" }}>
                                    <h2 style={{ color: "white" }}>$ 0.00</h2>
                                    <p style={{
                                        color: "#fff", marginLeft: "10px", marginTop: "-20px"
                                    }}>INST</p>
                                </Grid>
                                <Grid item style={{ width: "50%", textAlign: "center" }}>
                                    <h2 style={{ color: "white" }}>$ 0.00</h2>
                                    <p style={{
                                        color: "#fff", marginLeft: "10px", marginTop: "-20px"
                                    }}>INST</p>
                                </Grid>
                            </Grid>
                            <h2 style={{ color: "white", textAlign: "center" }}>
                                380.74%
                            </h2>
                            <p style={{
                                color: "#8385A5", marginLeft: "10px", marginTop: "-20px", textAlign: "center"
                            }}>
                                Reward APR
                            </p>
                            <Grid container style={{ textAlign: "center" }}>
                                <Grid item style={{ textAlign: "center", width: "50%", padding: "5px" }}>
                                    <div style={{ color: "white", border: "2px solid #8538BD", borderRadius: "10px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", paddingRight: "5px" }}>
                                        Perform a task
                                    </div>
                                </Grid>
                                <Grid item style={{ textAlign: "center", width: "50%", padding: "5px" }}>
                                    <div style={{ color: "white", border: "2px solid #8538BD", borderRadius: "10px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", paddingRight: "5px" }}>
                                        Withdraw
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ padding: "20px", borderRadius: "15px", width: "100%", minWidth: "320px", maxWidth: "320px", background: "#191D59", margin: "auto" }}>
                            <div >
                                <h3 style={{ color: "white", marginLeft: "10px" }}>$ 0.00</h3>
                                <p style={{
                                    color: "#8385A5", marginLeft: "10px", marginTop: "-20px"
                                }}>{"<"}0.001 ( INST-ETH )</p>
                            </div>
                            <Grid container style={{ background: "#8538BD", borderRadius: "10px", padding: "20px" }}>
                                <Grid item style={{ width: "50%", borderWidth: "0px 1px 0px 0px", borderColor: "white", borderStyle: "solid", textAlign: "center" }}>
                                    <h2 style={{ color: "white" }}>$ 0.00</h2>
                                    <p style={{
                                        color: "#fff", marginLeft: "10px", marginTop: "-20px"
                                    }}>INST</p>
                                </Grid>
                                <Grid item style={{ width: "50%", textAlign: "center" }}>
                                    <h2 style={{ color: "white" }}>$ 0.00</h2>
                                    <p style={{
                                        color: "#fff", marginLeft: "10px", marginTop: "-20px"
                                    }}>INST</p>
                                </Grid>
                            </Grid>
                            <h2 style={{ color: "white", textAlign: "center" }}>
                                380.74%
                            </h2>
                            <p style={{
                                color: "#8385A5", marginLeft: "10px", marginTop: "-20px", textAlign: "center"
                            }}>
                                Reward APR
                            </p>
                            <Grid container style={{ textAlign: "center" }}>
                                <Grid item style={{ textAlign: "center", width: "50%", padding: "5px" }}>
                                    <div style={{ color: "white", border: "2px solid #8538BD", borderRadius: "10px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", paddingRight: "5px" }}>
                                        Perform a task
                                    </div>
                                </Grid>
                                <Grid item style={{ textAlign: "center", width: "50%", padding: "5px" }}>
                                    <div style={{ color: "white", border: "2px solid #8538BD", borderRadius: "10px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px", paddingRight: "5px" }}>
                                        Withdraw
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid md={6} xs={12} style={{ padding: `${window.screen.width > 900 ? "10px 70px" : "10px"}` }}>
                    <Grid container style={{ textAlign: "" }}>
                        <Grid item style={{ textAlign: "", width: "70%" }}>
                            <h1 style={{ color: "white", textAlign: "" }}>KITE SYNC Pools</h1>

                        </Grid>
                        <Grid item style={{ textAlign: "center", width: "30%" }}>
                            <h1 style={{ color: "#8538BD", textAlign: "center" }}>406.41%</h1>
                            <p style={{
                                color: "#8385A5", marginLeft: "10px", marginTop: "-20px", textAlign: "center"
                            }}>
                                APR
                            </p>
                        </Grid>
                    </Grid>
                    <div style={{ minWidth: "320px", borderRadius: "10px", background: "#191D59", padding: "20px", margin: "auto" }}>
                        <p style={{
                            color: "#8385A5", marginLeft: "10px", textAlign: ""
                        }}>
                            Balance
                        </p>
                        <h1 style={{ color: "#fff", textAlign: "", marginTop: "-20px" }}>406.41%</h1>
                        <Grid container>
                            <Grid item style={{ width: "33%" }}>
                                <img src={deposit} style={{ margin: "auto", display: "block" }} />
                            </Grid>
                            <Grid item style={{ width: "33%" }}>
                                <img src={withdraw} style={{ margin: "auto", display: "block" }} />

                            </Grid>
                            <Grid item style={{ width: "33%" }}>
                                <img src={buy} style={{ margin: "auto", display: "block" }} />

                            </Grid>
                        </Grid>
                    </div>
                    <h1 style={{ color: "white", textAlign: "left" }}>Currency</h1>
                    <Grid container>
                        <Grid container style={{ width: "100%", margin: "auto", background: "#191D59", borderRadius: "10px", padding: "10px" }}>
                            <Grid item style={{ width: "70%" }}>
                                <h1 style={{ color: "#fff" }}>406.41%</h1>
                                <p style={{
                                    color: "#8385A5", marginLeft: "10px", marginTop: "-20px"
                                }}>
                                    APR
                                </p>
                            </Grid>
                            <Grid item style={{ width: "30%" }}>
                                <div style={{ border: "2px solid #8538BD", borderRadius: "10px", verticalAlign: "middle", marginTop: "20px", padding: "10px 20px", color: "white", fontWeight: "bold", textAlign: "center" }}>
                                    Trade
                                </div>
                                {/* <h1 style={{ color: "#fff" }}>406.41%</h1> */}
                                {/* <p style={{
                                    color: "#8385A5", marginLeft: "10px", marginTop: "-20px"
                                }}>
                                    APR
                                </p> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default PoolPage
