import React from 'react';

import AllIdoPage from 'features/vault/components/AllIdoPage/AllIdoPage';
import Disclaimer from 'components/Disclaimer/Disclaimer';

export default function HomePage() {
  return (
    <>
      {/* <Disclaimer /> */}
      <AllIdoPage fromPage="home" />
    </>
  );
}
